import { useEffect } from 'react';
import logo from './logo-dark.svg';
import './App.css';

function App() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('https://askorganizer-470e9.web.app/');
    }, 1500);
    
  }, [])
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Você está sendo redirecionado para o <span>askorganizer</span>
        </p>
      </header>
    </div>
  );
}

export default App;
